<template>
  <span class="leftTopPanelTitle mx-auto d-flex align-items-center justify-content-center flex-grow-0 flex-shrink-0">
    <mdb-icon
      v-if="icon != '' && !loading"
      :icon="icon"
      class="primary-colour ml-2"
      style="font-size:clamp(1rem, 2vw, 1.5rem);margin-right:10px;"
    />
    <i
      v-if="loading"
      class="fa fa-spinner fa-pulse primary-colour ml-2"
      style="font-size:clamp(1rem, 2vw, 1.5rem);margin-right:10px;"
    />
    <h2
      class="primary-colour font-weight-bold text-center mb-0"
      style="font-size: clamp(1rem, 2vw, 3rem); "
    >{{ text }}</h2>
  </span>
</template>

<script>

export default {
  name: 'LeftTopPanelTitle',
  props: {
    text: {
      type: [String],
      default: function () {
        return ''
      }
    },
    icon: {
      default: '',
      type: String
    },
    loading: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
.leftTopPanelTitle {
  height: 70px;
  width: 100%;
  border-bottom: 3px solid var(--pr-color);
}
@media (max-width: 768px) {
  .leftTopPanelTitle {
    height: 50px !important;
    margin-top: -15px;
    margin-bottom: 5px;
  }
}
</style>
