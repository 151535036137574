<template>
  <div class="row flex-nowrap mainContainer m-0">
    <div
      class="m-0 p-0"
      :class="panelViewedOnMobile === 'Left' ? 'col-12 col-md-8' : 'd-none flex-column d-md-flex col-md-8'"
    >
      <div
        style="background-color: var(--bg-color);border-radius: 10px;position: relative;"
        class=" d-flex flex-column mx-3 mt-3 px-3"
        :class="{'leftPanelInnerContainerHeightNoButtons': noLeftButtons && safari === false, 'leftPanelInnerContainerHeight' : !noLeftButtons && safari === false,'leftPanelInnerContainerHeightNoButtonsSafari': noLeftButtons && safari, 'leftPanelInnerContainerHeightSafari' : !noLeftButtons && safari }"
      >
        <!-- LEFT PANEL TOP SLOT -->
        <slot name="leftPanelTop" />
      </div>
      <div
        v-if="noLeftButtons === false"
        class="d-flex align-items-center justify-content-center mx-0 mx-sm-3 p-sm-3 bottomNavButtons"
        style="background-color: var(--bg-color);"
      >
        <!-- LEFT PANEL BUTTONS -->
        <slot name="leftPanelButtons" />
      </div>
    </div>
    <div
      class="d-md-flex flex-column px-3 pl-md-0 pt-3 pb-4 primary-colour m-0"
      style="background-color: var(--lk-color); height: 100%;"
      :class="{ 'col-12 col-md-4' : panelViewedOnMobile === 'Right', 'd-none d-md-flex col-md-4' : panelViewedOnMobile === 'Left' }"
    >
      <div
        class="d-flex flex-column align-items-center p-3"
        :class="{'dvhHeight': safari === false, 'noDvhHeight': safari }"
        style="background-color: var(--bg-color);border-radius:10px;"
      >
        <!-- RIGHT PANEL -->
        <slot name="rightPanel" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainContainer',
  components: {
  },
  props: {
    noLeftButtons: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['panelViewedOnMobile', 'safari'])
  }
}
</script>

<style>
:root {
  --messagePopupHeight: 60px;
}
.bottomNavButtons {
  border-radius: 10px;
  height: var(--bottom-button-container-height-desktop);
  margin-top: 20px;
  width: auto;
}
.mainContainer {
  height: calc(100vh - var(--desktop-nav-height));
  overflow-y: none;
}
.showPopupHeight {
  height: var(--messagePopupHeight);
}

/*dvh not iOS Mac-OS*/
.leftPanelInnerContainerHeight {
  height: calc(100dvh - var(--desktop-nav-height) - 60px - var(--bottom-button-container-height-desktop));
}
.leftPanelInnerContainerHeight.showPopup {
  height: calc(100dvh - var(--desktop-nav-height) - 60px - var(--bottom-button-container-height-desktop) - var(--messagePopupHeight));
}
.leftPanelInnerContainerHeightNoButtons {
  height: calc(100dvh - 40px - var(--desktop-nav-height));
}
.leftPanelInnerContainerHeightNoButtons.showPopup {
  height: calc(100dvh - 40px - var(--desktop-nav-height) - var(--messagePopupHeight));
}

/*vh for iOS Mac-OS*/
.leftPanelInnerContainerHeightSafari {
  height: calc(100vh - var(--desktop-nav-height) - 60px - var(--bottom-button-container-height-desktop));
}
.leftPanelInnerContainerHeightSafari.showPopup {
  height: calc(100vh - var(--desktop-nav-height) - 60px - var(--bottom-button-container-height-desktop) - var(--messagePopupHeight));
}
.leftPanelInnerContainerHeightNoButtonsSafari {
  height: calc(100vh - 40px - var(--desktop-nav-height));
}
.leftPanelInnerContainerHeightNoButtonsSafari.showPopup {
  height: calc(100vh - 40px - var(--desktop-nav-height) - var(--messagePopupHeight));
}
.dvhHeight {
  height:calc(100dvh - 140px);
}
.noDvhHeight {
  height:calc(100vh - 140px);
}
.rightPanelHasPopupHeight {
  height: 100%;
}
@media (max-width: 768px) {
  .rightPanelHasPopupHeight {
    height: calc(100% - var(--messagePopupHeight)) !important;
  }
}
@media (max-width: 576px) {
  .bottomNavButtons {
    width: 100%;
    position: fixed;
    bottom: 0px;
    border-radius: 0px;
    height: var(--bottom-button-container-height-mobile);
    margin-top: 20px;
  }
  .mainContainer {
    height: calc(100vh - var(--mobile-nav-height));
  }
  .leftPanelInnerContainerHeight {
    height: calc(100dvh - var(--mobile-nav-height) - var(--bottom-button-container-height-desktop) - 20px);
  }
  .leftPanelInnerContainerHeight.showPopup {
    height: calc(100dvh - var(--mobile-nav-height) - var(--bottom-button-container-height-desktop) - 20px - var(--messagePopupHeight));
  }
  .leftPanelInnerContainerHeightNoButtons {
    height: calc(100dvh - var(--mobile-nav-height) - 20px);
  }
  .leftPanelInnerContainerHeightNoButtons.showPopup {
    height: calc(100dvh - var(--mobile-nav-height) - 20px - var(--messagePopupHeight));
  }
  .leftPanelInnerContainerHeightSafari {
    height: calc(100vh - var(--mobile-nav-height) - var(--bottom-button-container-height-desktop) - 20px);
  }
  .leftPanelInnerContainerHeightSafari.showPopup {
    height: calc(100vh - var(--mobile-nav-height) - var(--bottom-button-container-height-desktop) -20px - var(--messagePopupHeight));
  }
  .leftPanelInnerContainerHeightNoButtonsSafari {
    height: calc(100vh - 40px - var(--mobile-nav-height));
  }
  .leftPanelInnerContainerHeightNoButtonsSafari.showPopup {
    height: calc(100vh - 40px - var(--mobile-nav-height) - var(--messagePopupHeight));
  }
}
</style>
