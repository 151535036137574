<template>
  <div style="height: 100%;font-size: 0.9rem;">
    <div class="text-left primary-colour">
      <span class="d-block mt-3">
        If you wish to close your account, all your personal details will be deleted and you will no longer be able to access this site. To do so, you will need
        to re-register.
      </span>
      <span class="d-block mt-3">Any pending entries you have will remain valid, but if you should win prize money will be shared between our partner charities.</span>
      <span class="d-block mt-3">You will be sent confirmation details in an email. Note that upon comfirming you will also be automatically logged out.</span>
    </div>
    <div
      class="d-flex align-items-center mt-3 cp"
      style="transform: translateX(-20px);font-size: 0.9rem;"
      @click="toggleAgree()"
    >
      <mdb-input
        v-model="agree"
        type="checkbox"
        auto-complete="ex-agr"
        size="sm"
      />
      <div class="white-text mb-2">
        I confirm I wish to close my account.
      </div>
    </div>
    <div class="mt-3">
      <mdb-btn
        v-if="updated === false"
        v-show="!buttonClick"
        :disabled="agree === false"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="closeAccount()"
      >
        CONFIRM
      </mdb-btn>
      <mdb-btn
        v-if="updated === false"
        v-show="buttonClick"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <mdb-btn
        v-if="updated === true"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-check" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'

export default {
  name: 'CloseAccount',
  components: {
  },
  props: {
  },
  data () {
    return {
      agree: false,
      buttonClick: false,
      updated: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  methods: {
    toggleAgree () {
      if (this.agree === true) {
        this.agree = false
      } else {
        this.agree = true
      }
    },
    closeAccount () {
      this.buttonClick = true
      userService.closeAccount(this.userData.access_token, this.userData.une).then((results) => {
        console.log(results.data)
        this.agree = false
        this.updated = true
        setTimeout(() => { this.logout() }, 3000)
      }).catch(() => {
        this.buttonClick = false
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      })
    },
    logout () {
      this.$store.commit('storeUserData', { aut: false })
      this.$store.commit('storeId', 0)
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
      this.$store.commit('clearBasket')
      this.$store.commit('storeLoginData', true)
      this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  @media (max-width: 576px) {
    .tick {
      margin-top: -30px !important;
    }
    .bfr {
      float:right !important;
    }
  }
</style>
