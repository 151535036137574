<template>
  <div style="height: 100%;max-width: 100%;overflow-x: hidden;">
    <div
      class="primary-colour mx-auto pb-2 pb-md-3"
      style="width: 100%; background: var(--bg-color);font-size: 0.9rem;"
    >
      <div
        v-if="existingUser.tmp"
        style="border: solid 2px red;padding: 10px;margin-top:10px;margin-bottom: 20px;"
      >
        <div style="color:red;text-align:center;">
          This is a reminder that you should update your temporary password.
        </div>
      </div>
      <span class="d-block mt-3">
        Update your existing account by amending your details below (please ensure your contact details, email and telephone number are up-to-date).
      </span>
    </div>

    <validation-observer>
      <form style="width:100%;max-width: 100%;" autocomplete="off">
        <div class="md-form">
          <div
            class="md-form form-sm formMarginTop"
          >
            <validation-provider
              mode="lazy"
              name="Forename"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="existingUser.fnm"
                type="text"
                auto-complete="ex-fnm"
                label="Forename"
                style="margin-left:33px;max-width:100%;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Surname"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="existingUser.lnm"
                type="text"
                auto-complete="ex-lnm"
                label="Surname"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Email"
              rules="required"
            >
              <i class="fas fa-envelope prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="existingUser.une"
                type="email"
                auto-complete="ex-une"
                label="Email"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:-15px;float:left;margin-bottom:-10px;">
            <validation-provider
              mode="lazy"
              name="Date of birth"
              rules="required"
            >
              <i class="fas fa-birthday-cake prefix sm" style="left:0;margin-top:33px;font-size:1rem;" />
              <mdb-input
                v-model="existingUser.day"
                type="text"
                auto-complete="ex-day"
                label="DD"
                style="margin-left:33px;margin-right:10px;width:27%;float:left;"
                size="sm"
              />
              <mdb-input
                v-model="existingUser.mth"
                type="text"
                auto-complete="ex-mth"
                label="MM"
                style="margin-right:10px;width:27%;float:left;"
                size="sm"
              />
              <mdb-input
                v-model="existingUser.yer"
                type="text"
                auto-complete="ex-yer"
                label="YYYY"
                style="width:27%;float:left;"
                size="sm"
                class="mobile-date"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:105px;">
            <validation-provider
              mode="lazy"
              name="First line of address"
              rules="required"
            >
              <i class="fas fa-home prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="existingUser.adr"
                type="text"
                auto-complete="ex-add"
                label="First line of address"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Postcode"
              rules="required"
            >
              <i class="fas fa-map-pin prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="existingUser.pce"
                type="text"
                auto-complete="ex-pcd"
                label="Postcode"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Telephone"
              rules="required"
            >
              <i class="fas fa-phone prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="existingUser.tel"
                type="text"
                auto-complete="ex-tel"
                label="Telephone"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div
            class="md-form form-sm"
            style="margin-top:25px;"
            @click="toggleAgree()"
          >
            <mdb-input
              v-model="agree"
              type="checkbox"
              auto-complete="ex-agr"
              style="margin-left:-20px;"
              size="sm"
            />
            <div style="margin-left: 33px;margin-top: -17px;font-size: small;">
              Tick to confirm you are changing your details.
            </div>
          </div>
        </div>
      </form>
      <div>
        <mdb-btn
          v-if="updated === false && error === false"
          v-show="!buttonClick"
          :disabled="agree === false"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
          @click="updateDetails(false)"
        >
          UPDATE
        </mdb-btn>
        <mdb-btn
          v-if="updated === false && error === false"
          v-show="buttonClick"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
        <mdb-btn
          v-if="updated"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        >
          <mdb-icon
            icon="fa fa-check"
            style="font-size:1rem;" 
          />
        </mdb-btn>
        <mdb-btn
          v-if="error"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        >
          <mdb-icon
            icon="fa fa-times"
            style="font-size:1rem;" 
          />
        </mdb-btn>
      </div>
      <div
        v-if="error"
        class="errMsg"
      >
        SORRY, THERE WAS AN ERROR AMENDING YOUR DETAILS, PLEASE TRY AGAIN!
      </div>
      <div
        v-if="updated"
        class="errMsg"
      >
        DETAILS SUCCESSFULLY UPDATED!
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'

export default {
  name: 'MyDetails',
  components: {
  },
  data () {
    return {
      existingUser: {
        fnm: '',
        lnm: '',
        une: '',
        day: '',
        mth: '',
        yer: '',
        adr: '',
        pce: '',
        tel: '',
        pwd: '',
        new: '',
        con: ''
      },
      buttonClick: false,
      agree: false,
      updated: false,
      error: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  created () {
    this.getMyDetails()
  },
  methods: {
    toggleAgree () {
      if (this.agree === true) {
        this.agree = false
      } else {
        this.agree = true
      }
    },
    updateDetails () {
      userService.editMyDetails(this.userData.access_token, false, this.existingUser).then((results) => {
        console.log(results.data)
        this.agree = false
        this.buttonClick = false
        this.updated = true
        setTimeout(() => { this.updated = false }, 5000)
      }).catch(() => {
        this.buttonClick = false
        this.error = true
        setTimeout(() => { this.error = false }, 5000)
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      })
    },
    getMyDetails () {
      userService.getMyDetails(this.userData.access_token).then((results) => {
        this.existingUser = { ...results.data }
      }).catch(() => {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .errMsg {
    color: white;
    margin-top: 15px;
    margin-left: 5px !important;
    float: left;
  }
  @media (max-width: 576px) {
    .errMsg {
      color: white;
      margin-top: 10px;
      margin-left: 0px !important;
      float: left;
    }
  }
</style>
