<template>
  <div style="height: 100%;font-size: 0.9rem;">
    <div class="text-left primary-colour">
      <span class="d-block mt-3">
        From time to time we reward <span style="color:white;">#GrassRootsHeroes</span> with credits.
        <br><br>
        If you have any credits they will be used to pay for one hero and give you a free entry into a whole month of draws.
        <br><br>
        Currently your total credits stand at: <span style="color:white;">{{ userData.crd }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CloseAccount',
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  methods: {
  }
}
</script>
