<template>
  <MainContainer :no-left-buttons="true">
    <template #leftPanelTop>
      <MyAccount />
    </template>

    <template #rightPanel>
      <SideMenu />
    </template>
  </MainContainer>
</template>

<script>
import SideMenu from '@/components/navbar/sideMenu.vue'
import MainContainer from '@/components/layout/mainContainer.vue'
import MyAccount from '@/components/myAccount/myAccount.vue'

export default {
  name: 'MyAccountContainer',
  components: {
    MainContainer, SideMenu, MyAccount
  }
}
</script>
